html,
body,
#root {
  height: 100%;
  font-family: Zoho_Puvi_Regular, Spartan, Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background: #edf0f4;
}
.creditnigeria-app {
  iframe {
    display: none;
  }
}
.creditnigeria-app {
  background: #edf0f4;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-nav-bar {
  background-color: rgba(255, 255, 255, 1);
  border-bottom-width: 0px;
  border-bottom-style: solid;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin-bottom: 24px;
  border-radius: 0;
  .rs-navbar-brand {
    padding: 5px 0 0 0;
  }
  a {
    color: #575757;
    text-decoration: none;
  }
}

.app-header {
  background-color: rgba(255, 255, 255, 1);
  border-bottom-width: 0px;
  border-bottom-style: solid;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 24px 0;
  border-radius: 12px;
}
.page-body {
  background-color: rgba(255, 255, 255, 1);
  border-bottom-width: 0px;
  border-bottom-style: solid;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 0 24px 24px 24px;
  border-radius: 12px;
}
.page-footer {
  margin: 24px 0;
  text-align: center;
  button {
    margin-left: 12px;
  }
}
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
}

.user-reg-form {
  margin: 44px auto;
  max-width: 820px;
  .form-title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .form-info-title {
    font-weight: 500;
    font-size: 16px;
    color: #006694;
    text-align: center;
    line-height: 22px;
    margin-top: 0;
  }
  .rs-form-control-label {
    display: block;
    font-size: 16px;
    margin-bottom: 6px;
    color: #393939;
  }
  .rs-radio-group {
    flex-direction: row;
    gap: 24px;
  }
  .rs-picker-toggle-wrapper {
    width: 380px;
  }
  .rs-form-group {
    min-width: 380px;
  }
  .rs-form-control-wrapper > .rs-input,
  .rs-form-control-wrapper > .rs-input-number,
  .rs-picker-toggle-wrapper {
    width: 100%;
    min-width: 380px;
  }
  .rs-form-control-wrapper > .rs-input,
  .rs-form-control-wrapper > .rs-input-number,
  .rs-radio-wrapper .rs-radio-inner:before,
  .rs-picker-default .rs-picker-toggle {
    border-color: #000;
  }

  .rs-picker-toggle-caret {
    color: #ff5817;
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #393939;
  }
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
  color: #393939 !important;
}

.rs-btn {
  &.rs-btn-primary {
    background-color: #ff5817;
    &.green-btn {
      background-color: #2daf7d;
    }
  }
  &.rs-btn-ghost {
    color: #fe9870;
    border-color: #fe9870;
    &:hover,
    &:focus {
      border-color: #fe9870;
      box-shadow: none;
    }
  }
}

.rs-input-group,
.rs-input,
.rs-picker-toggle {
  // border-color: #fe9870 !important;
  &:hover,
  &:focus {
    border-color: #fe9870 !important;
    outline: 3px solid #f3edde !important;
  }
}
.rs-radio {
  .rs-radio-wrapper {
    .rs-radio-inner {
      &:before {
        // border-color: #fe9870 !important;
      }
    }
  }
  &:hover {
    .rs-radio-wrapper {
      .rs-radio-inner {
        &:before {
          border-color: #fe9870 !important;
        }
      }
    }
  }
  &.rs-radio-checked .rs-radio-inner:before {
    background-color: #fe9870 !important;
    border-color: #fe9870 !important;
  }
}
.rs-steps {
  padding: 36px 56px 0 56px;
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: #fe9870;
    background-color: #fe9870;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: #fe9870;
    color: #fe9870;
  }
  &.rs-steps-horizontal .rs-steps-item-title:after {
    border-color: #fe9870;
  }
}

.primary-dropdown {
  border: 1px solid #fe9870;
  border-radius: 6px;
  .rs-dropdown-toggle {
    background: transparent;
    color: #ed4402;
    font-weight: 600;
  }
}

.rs-dropdown-item.rs-dropdown-item-active,
.rs-dropdown-item.rs-dropdown-item-focus,
.rs-dropdown-item.rs-dropdown-item-active:focus,
.rs-dropdown-item.rs-dropdown-item-active:hover {
  color: #000 !important;
}

.salary-amount-input {
  position: relative;
  span.currency {
    position: absolute;
    left: 13px;
    top: 17px;
    bottom: 0;
    text-align: center;
    margin: auto;
    height: 20px;
    z-index: 1;
    font-size: 15px;
  }
  .rs-input-lg {
    padding-left: 58px;
  }
}

.full-width {
  padding-right: 20px;
  .rs-form-group,
  .rs-form-control {
    width: 100%;
  }
  .rs-form-inline .rs-form-group .rs-form-control-wrapper {
    width: 100%;
  }
}

// Responsive

@media only screen and (max-width: 980px) {
  .rs-steps {
    .rs-steps-item-content {
      .rs-steps-item-title {
        line-height: 16px;
      }
    }
  }
  .full-width {
    padding-right: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 896px) {
  body {
    .rs-form-group,
    .rs-form-inline .rs-form-group .rs-form-control-wrapper {
      width: 100%;
    }
    .user-reg-form {
      .rs-form-control-wrapper > .rs-input,
      .rs-form-control-wrapper > .rs-input-number,
      .rs-picker-toggle-wrapper {
        width: 100% !important;
        min-width: auto;
      }
    }
  }
}

@media only screen and (max-width: 740px) {
  body {
    .rs-steps {
      .rs-steps-item-content {
        display: none;
      }
    }
    .user-reg-form .rs-form-group {
      min-width: 100%;
    }
  }
}

@media only screen and (max-width: 520px) {
  body {
    .rs-steps {
      .rs-steps-item-content {
        display: none;
      }
    }
    .rs-navbar-brand {
      display: block;
      width: 100%;
      text-align: center;
    }
    .app-nav-bar {
      a {
        padding: 8px;
        height: auto;
      }
    }
    .user-reg-form .rs-form-group {
      min-width: 100%;
    }
  }
}
