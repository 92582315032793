.application-page {
  .user-reg-form {
    .form-info-title {
      font-weight: bold;
      margin: 24px 0;
    }
  }
}

@media only screen and (max-width: 740px) {
  .application-page {
    .user-reg-form {
      .form-title {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .application-page {
    .page-body {
      margin: 0 12px 24px 12px;
    }
  }
  .details-modal.rs-modal-sm {
    right: 6px !important;
  }
}
