.page-container {
  padding-bottom: 24px;
}
.borrower-dashboard-page {
  max-width: 1280px;
  min-height: calc(100vh - 126px);
  margin: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 12px;
  width: calc(100% - 48px);
  // border: 1px solid #cfcfcf;
  // background-color: #fff;
  // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  // padding: 34px;
  .recommend-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 26px;
    width: 100%;
    margin-bottom: 30px;
    .recommend-box {
      display: flex;
      flex-direction: column;
      border: 1px solid #dbdbdb;
      background-color: #ffffff;
      padding: 34px 24px 24px 24px;
      border-radius: 10px;
      height: auto;
      width: 100%;
      min-width: 180px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      position: relative;
      &.highlight {
        border: 2px solid #1b5998;
        .highlight-title {
          position: absolute;
          left: 0;
          top: 0;
          background: #1b5998;
          color: #fff;
          padding: 6px 14px;
          font-size: 13px;
          border-radius: 4px 0 0 0;
          font-weight: bold;
        }
      }
      .rs-btn.rs-btn-primary {
        height: 42px;
        font-size: 15px;
      }
    }
    .recommed-overview {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .logo {
        img {
          min-width: 140px;
          max-width: 162px;
          height: auto;
          width: auto;
        }
      }
      .overview-info {
        flex: 1;
        .overview-info-wrapper {
          display: flex;
          justify-content: space-between;
        }
      }

      .overview {
        .up-to {
          display: block;
        }
        &.logo {
          width: 200px;
        }
        &.loan-amount {
          width: 240px;
          .value {
            position: relative;
            top: -12px;
          }
          .title {
            position: relative;
            top: -13px;
          }
        }
        &.loan-btn {
          width: 142px;
        }
      }
    }
    .logo {
      text-align: left;
      .title {
        font-size: 18px;
        margin-top: 10px;
        color: #242424;
        text-transform: uppercase;
      }
    }
    .loan-amount,
    .org-fees,
    .serivce-fees {
      text-align: center;
    }
    .interest-rate {
      text-align: center;
      .rs-btn.rs-btn-primary {
        font-size: 20px;
        letter-spacing: 0.32px;
        background-color: #f05e24;
        height: 42px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      }
    }
    .title {
      margin: 0;
      margin-top: 6px;
      font-size: 20px;
      color: #3d3d3d;
      font-weight: 500;
    }
    .value {
      margin: 0px 0 8px 0;
      font-size: 24px;
      color: #2f2f2f;
      font-weight: 600;
    }
    .stats-value {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      margin-top: 21px;
      color: #4d4d4d;
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
    }
    .separator {
      border-left: 1px solid #a8a8a8;
      width: 1px;
      height: 56px;
    }
    .green {
      svg {
        color: #036803;
      }
    }
    .red {
      svg {
        color: #d00202;
      }
    }
    .orange {
      color: #e26f42;
    }
  }

  .page-title {
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 12px 0;
  }

  .tag-filters {
    margin: 12px 0 24px;
  }
  .other-fees {
    text-align: center;
    .title {
    }
  }
}

.success-modal {
  .rs-modal-body {
    min-height: 72px;
  }
}

.message {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  &.error {
    text-transform: inherit;
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      color: #ff5817;
    }
  }
}

.confirm-msg {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobile-view {
  display: none;
}

.page-center-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  .message {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1140px) {
  .borrower-dashboard-page {
    height: auto;
    .recommend-wrapper {
      .recommend-box {
        .recommed-overview {
          .title {
            font-size: 18px;
          }
          .value {
            font-size: 20px;
          }
          .logo img {
            max-width: 120px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .borrower-dashboard-page {
    height: auto;
    .recommend-wrapper {
      .recommend-box {
        .recommed-overview {
          flex-direction: column;
          gap: 26px;
          .overview {
            min-width: 200px;
            .up-to {
              display: inline-block;
              margin-right: 6px;
            }
            &.loan-amount {
              .value {
                top: 0;
              }
              .title {
                top: 0;
              }
            }
          }
          .logo,
          .interest-rate {
            text-align: center;
          }
        }
        .description {
          margin-top: 26px;
          text-align: center;
        }
        .cta-button {
          text-align: center;
        }
      }
    }
  }
  .mobile-view {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  .borrower-dashboard-page {
    height: auto;
    .recommend-wrapper {
      .recommend-box {
        .recommed-overview {
          .logo img {
            max-width: 200px;
          }
          .title {
            font-size: 18px;
          }
          .value {
            font-size: 20px;
          }
          .separator {
            height: 60px;
          }

          flex-direction: column;
          gap: 26px;
          .overview-info-wrapper {
            gap: 12px;
          }
          .overview {
            min-width: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .borrower-dashboard-page {
    height: auto;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;

    .recommend-wrapper {
      .recommend-box {
        background-color: #ffffff;
        .recommed-overview {
          .title {
            font-size: 12px;
          }
          .value {
            font-size: 14px;
          }
          .separator {
            height: 42px;
          }
        }
      }
    }
    .page-title {
      text-align: center;
    }
    .recommend-wrapper .value {
      font-size: 18px;
    }
    .recommend-wrapper .interest-rate .rs-btn.rs-btn-primary {
      font-size: 14px;
      height: 30px;
    }
  }
}
