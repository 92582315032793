@media only screen and (max-width: 980px) {
  .offers-page {
    .offers-page-container {
      .offers-page-body {
        background-color: transparent;
        border-bottom-width: 0px;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
