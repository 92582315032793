.page-container {
  padding-bottom: 24px;
}
.dashboard-page {
  max-width: 1280px;
  width: calc(100% - 48px);
  margin: auto;
  border: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 34px;
  .stats-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    .stats-box {
      display: flex;
      flex-direction: column;
      border: 1px solid #dbdbdb;
      padding: 14px;
      border-radius: 10px;
      height: auto;
      min-width: 180px;
      width: 100%;
      // width: calc(25% - 24px);
      // height: 140px;
      // box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    }
    .title {
      margin: 0;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #777676;
    }
    .value {
      margin: 6px 0 0 0;
      font-size: 28px;
      font-weight: 600;
      color: #2f2f2f;
    }
    .stats-value {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      margin-top: 21px;
      color: #4d4d4d;
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
    }
    .green {
      svg {
        color: #036803;
      }
    }
    .red {
      svg {
        color: #d00202;
      }
    }
    .orange {
      color: #e26f42;
    }
  }

  .page-title {
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 12px 0;
  }

  .table-wrapper {
    height: 400px;
    border-top: 1px solid #cfcfcf;
    max-height: 100%;
    position: relative;
    // .rs-table {
    //   height: calc(100% - 60px) !important;
    // }
    // .rs-table-body-row-wrapper {
    //   height: 350px;
    // }
    .rs-table-header-row-wrapper {
      font-size: 11px;
      text-transform: uppercase;
    }
    .rs-table-body-row-wrapper {
      font-size: 13px;
      color: #232323;
    }
    .action-icon-btn {
      border-radius: 50%;
      position: relative;
      top: -9px;
    }
  }
  .pagination-wrapper {
    padding-top: 24px;
  }

  .filters-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 10px;
    .tag-filters {
      margin: 0;
    }
    .search-container {
      display: flex;
      gap: 16px;
      .rs-btn {
        svg {
          margin-right: 6px;
        }
      }
    }
    .search-wrapper {
      min-width: 320px;
    }
  }
}

.csv-download-btn {
  background-color: transparent;
  padding: 0;
}

.details-modal.rs-modal-sm {
  float: right;
  right: 12px;
  top: 0;
  margin-top: 12px;
  height: calc(100vh - 24px);
  .rs-modal-dialog,
  .rs-modal-content {
    height: 100%;
  }
  .rs-modal-title {
    font-size: 20px;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 12px;
  }
  .rs-modal-body {
    height: calc(100% - 90px);
    max-height: calc(100% - 90px) !important;
    font-size: 16px;
  }
  .list-items {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #cfcfcf;
    .list-title {
      margin-bottom: 24px;
      font-size: 20px;
    }
    .list-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      .label {
        flex: 0 50%;
        color: #777676;
      }
      .value {
        font-weight: 500;
      }
    }
  }
}

.confirm-dialog {
  .new-status {
    font-weight: bold;
  }
}

@media only screen and (max-width: 980px) {
  .dashboard-page {
    height: auto;
    .stats-wrapper {
      .stats-box {
      }
    }
    .table-wrapper {
      height: 400px;
    }
  }
}

@media only screen and (max-width: 760px) {
  .dashboard-page {
    .stats-wrapper {
      flex-direction: column;
      .stats-box {
        width: 100%;
      }
    }
    .filters-wrapper {
      align-items: flex-start;
      flex-direction: column;
    }
    .pagination-wrapper {
      .rs-pagination-group-total,
      .rs-pagination-group-limit,
      .rs-divider,
      .rs-pagination-group-skip {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .dashboard-page {
    width: calc(100% - 18px);
    padding: 18px;
    .stats-wrapper {
      gap: 12px;
      .value {
        margin: 0;
      }
      .stats-value {
        margin-top: 12px;
      }
    }

    .filters-wrapper {
      align-items: flex-start;
      flex-direction: column;
      .search-container {
        width: 100%;
        .rs-btn svg {
          margin-right: 0;
        }
        .download-btn .download-text {
          display: none;
        }
      }
      .search-wrapper {
        min-width: calc(100% - 50px);
      }
    }
  }
}
